import React, { useContext, useState, useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import { Context } from "../store/appContext";
import { Redirect } from "react-router-dom";
import logo from "../assets/img/logo.webp"

export const Header = () => {
    const { store } = useContext(Context);
    const [auth, setAuth] = useState(false);
    let refrescar = () => {
        window.localStorage.removeItem("my_token");
        window.location.reload(true)
    };

    useEffect(() => {
        const loggedUser = window.localStorage.getItem('my_token');
        const user = JSON.parse(loggedUser);
    }, []);
    let roy = window.localStorage.getItem("my_token", JSON.stringify());
    let nav = "d-none";
    if (!roy) {
        nav = "d-none";
    } else {
        nav = "d-block col-10 pt-3";
    }
    return (
        <div className="container-fluid bg-header">
            <div className="row g-0" id="navbarNavAltMarkup">
                <div className="col-lg-2">
                    <HashLink to="/admin">
                        <img src={logo} className="img-fluid" alt="logo of the site"></img>
                    </HashLink>
                </div>
                <div className={nav}>
                    <div className="row g-0 text-center">
                        <div className="col-11">
                            <nav className="row">
                                <div className="col-2 ul-drop">
                                    <span className="text-white">Noticias</span>
                                    <ul className="">
                                        <li className="li_drop">
                                            <HashLink className="text-white" to="/noticias">Crear Noticia</HashLink>
                                        </li>
                                        <li className="li_drop">
                                        <HashLink className="text-white" to="/admin">Noticas Creadas</HashLink>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-2 ul-drop">
                                    <span className="text-white">Noticias Skins</span>
                                    <ul className="">
                                        <li className="li_drop">
                                        <HashLink className="text-white" to="/noticias_skins">Crear Noticia para Skins</HashLink>
                                        </li>
                                        <li className="li_drop">
                                            <HashLink className="text-white" to="/noticias_skin_creadas">Noticias para Skins Creadas</HashLink>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-2 ul-drop">
                                    <span className="text-white">Skins</span>
                                    <ul className="">
                                        <li className="li_drop">
                                        <HashLink className="text-white" to="/skins">Crear nombre  de Skins</HashLink>
                                        </li>
                                        <li className="li_drop">
                                        <HashLink className="text-white" to="/lista_skins">Lista de Skins Creados</HashLink>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-2 ul-drop">
                                    <span className="text-white">Deportes</span>
                                    <ul className="">
                                        <li className="li_drop">
                                            <HashLink className="text-white" to="/deporte">Deportes</HashLink>
                                        </li>
                                        <li className="li_drop">
                                            <HashLink className="text-white" to="/lista_deportes">Lista de Deportes</HashLink>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                        <div className="col-1">
                            <div className="row g-0">
                                <div className="col-12 fw-bold text-white">{store.username_temp}</div>
                                <div className="col-12 float-left" id="btnLogOut">
                                    <a href="#" onClick={refrescar} className="text-danger ps-3 fs-3">
                                        <span>
                                            <i className="fas fa-sign-out-alt" />
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {auth ? <Redirect to="/" /> : null}
        </div>
    )
}