import { useParams, Redirect } from 'react-router-dom';
import React, { useState, useContext, useEffect } from "react";
import { Context } from "../store/appContext";
import "../../node_modules/aos/dist/aos.css";
import { HashLink } from 'react-router-hash-link';
export const Edith_Noticias_Skins = (props) => {
    const params = useParams();
    const { store } = useContext(Context);
    let date = new Date();
 

    let roy = window.localStorage.getItem("my_token", JSON.stringify());
    if (!roy) {
        window.location.href = '/';
    } else {

    }
    const [descripcion, setdescripcion] = useState(store.noticias_skins[params.theid] && store.noticias_skins[params.theid].descripcion);
    const [year, setyear] = useState(store.noticias_skins[params.theid] && store.noticias_skins[params.theid].year);
    const [mes, setmes] = useState(store.noticias_skins[params.theid] && store.noticias_skins[params.theid].mes);
    const [dia, setdia] = useState(store.noticias_skins[params.theid] && store.noticias_skins[params.theid].dia);
    const [h1, seth1] = useState(store.noticias_skins[params.theid] && store.noticias_skins[params.theid].h1);
    const [skin, setSkin] = useState(store.noticias_skins[params.theid] && store.noticias_skins[params.theid].skin);
    const [imagen_principal, setimagen_principal] = useState(store.noticias_skins[params.theid] && store.noticias_skins[params.theid].imagen_principal);



    const [auth, setAuth] = useState(false);
    let actualizar = () => {
        setTimeout(function () { window.location.reload(true); }, 800);

    }
    const crear = e => {
        e.preventDefault();
        const body = {
            skin: skin,
            descripcion: descripcion,
            year: year,
            mes: mes,
            dia: dia,
            h1: h1,
            imagen_principal:imagen_principal
        };


        fetch("https://noticiasdeportivas.herokuapp.com/noticias_skins/" + store.noticias_skins[params.theid].id, {
            method: "PUT",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "https://localhost:3000/",
                "Access-Control-Allow-Methods": "PUT"
            }
        })
            .then(res => res.json())
            .then(data => {
                sessionStorage.setItem("my_token", data.token);

                alert("Noticia se Actualizó");
                setAuth(true);
                actualizar();
            })
            .catch(err => console.log(err));

    };
    const delet = e => {
        fetch("https://noticiasdeportivas.herokuapp.com/noticias_skins/" + store.noticias_skins[params.theid].id, {
            method: "DELETE",
            headers: { "Content-Type": "application/json" }
        })
            .then(res => res.json())
            .catch(err => console.log(err));
        setAuth(true);
        actualizar();
    };

    //select
    let selectYear = [];
    for (let i = 2022; i < 2050; i++) {
        selectYear.push(i);
    }
    let selectMonth = [];
    for (let i = 0; i < 24; i++) {
        if (i < 10) {
            i = "0" + i;
            selectMonth.push(i);
        } else {
            selectMonth.push(i);
        }
    }
    let selectDay = [];
    for (let i = 1; i < 32; i++) {
        if (i < 10) {
            i = "0" + i;
            selectDay.push(i);
        } else {
            selectDay.push(i);
        }
    }
    return (
        <div className="container-fluid p-0 m-0 accordion" id="gameCreate" >
            <div className="col-12 bg-title-edith d-flex justify-content-center align-items-center">
                <h1>Crear Noticia para Skin</h1>
            </div>
            <form onSubmit={crear} id="miFormNoticia">
                <div className="container g-0">
                    <div className="row g-0">
                        <div className="text-center col-1 p-1">
                            <div className="col-12 title-lines text-center">Año</div>
                            <select className="form-select shadow" name="year" aria-label="Ano" onChange={e => setyear(e.target.value)} defaultValue={store.noticias_skins[params.theid] && store.noticias_skins[params.theid].year}>
                                {
                                    selectYear.map((index) => {
                                        return (
                                            <option key={index} name="ano" value={index} >{index}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="text-center col-1 p-1">
                            <div className="col-12 title-lines text-center">Mes</div>
                            <select className="form-select shadow" name="month" aria-label="Default select example" defaultValue={store.noticias_skins[params.theid] && store.noticias_skins[params.theid].mes} onChange={e => setmes(e.target.value)}>
                                {
                                    selectMonth.map((index) => {
                                        return (
                                            <option key={index} name="promotions" value={index}>{index}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="text-center col-1  p-1">
                            <div className="col-12 title-lines text-center">Día</div>
                            <select className="form-select shadow" name="day" aria-label="Default select example" defaultValue={store.noticias_skins[params.theid] && store.noticias_skins[params.theid].dia} onChange={e => setdia(e.target.value)}>
                                {
                                    selectDay.map((index) => {
                                        return (
                                            <option key={index} name="promotions" value={index}>{index}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="text-center col-2 p-1">
                            <div className="col-12 title-lines text-center">Skin</div>
                            <select className="form-select selectInner" name="week" aria-label="Default select example" defaultValue={store.noticias_skins[params.theid] && store.noticias_skins[params.theid].skin} onChange={e => setSkin(e.target.value)}>
                                {
                                    store.skins.map((item, index) => {
                                        return (
                                            <option key={index} name="promotions" value={item.name}>{item.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-4 p-1">
                            <div className="col-12 title-lines text-center">Titulo</div>
                            <input className="form-control selectInner" type="text" placeholder="H1" defaultValue={store.noticias_skins[params.theid] && store.noticias_skins[params.theid].h1} aria-label="h1" onChange={e => seth1(e.target.value)} />
                        </div>
                        <div className="col-3 p-1">
                            <div className="col-12 title-lines text-center">Imagen</div>
                            <input className="form-control selectInner" type="text" placeholder="H1" defaultValue={store.noticias_skins[params.theid] && store.noticias_skins[params.theid].imagen_principal} aria-label="h1" onChange={e => setimagen_principal(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-12 shadow my-3">
                        <div className="col-12 title-lines text-center">Descripción</div>
                        <textarea id="mensaje" className="form-control selectInner" type="text" placeholder="Max 500 caracteres" maxLength="500" aria-label="Descripcion" defaultValue={store.noticias_skins[params.theid] && store.noticias_skins[params.theid].descripcion} rows="3" onChange={e => setdescripcion(e.target.value)} />
                    </div>
                </div>
                <div className="row g-0">
                    <div className="col-4 p-3 text-end">
                        <HashLink className="btn btn-secondary  text-white" to="/admin">Back to Admin</HashLink>
                    </div>
                    <div className="col-4 text-center p-3">
                        <button type="submit" className="btn btn-success">Edit</button>
                    </div>
                    <div className="col-4 text-start p-3">
                        <div data-bs-toggle="modal" data-bs-target="#delete" className="btn btn-danger">Delet</div>
                    </div>
                </div>
            </form>
            {auth ? <Redirect to="/admin" /> : null}
            <div className="modal fade" id="delete" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="deleteLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content overflow-hidden">
                        <div className="col-12 text-center text-white bg-danger text-uppercase fs-5 py-3">
                            <i className="fas fa-exclamation-triangle fs-1"></i><br />are you sure you want to delete the match
                        </div>
                        <div className="row g-0">
                            <div className="col-6 p-2 text-center">
                                <button className="btn btn-danger" onClick={delet} data-bs-dismiss="modal">Yes Delete</button>
                                {auth ? <Redirect to="/admin" /> : null}
                            </div>
                            <div className="col-6 p-2 text-center">
                                <button type="button" className="btn btn-secondary text-white" data-bs-dismiss="modal">No</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}