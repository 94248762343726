import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { Helmet } from "react-helmet";
import React, { useState, useEffect, useContext } from "react";
import { Context } from "../store/appContext";

import "../../node_modules/aos/dist/aos.css";
import List_Noticias from "../noticias/listNoticias";

export const Admin = () => {
    const { store, actions } = useContext(Context);
    let roy = window.localStorage.getItem("my_token", JSON.stringify());
    if (!roy) {
        window.location.href = '/';
    } else {

    }

    let date = new Date();
    let years = date.getFullYear();
    let days = date.getDate();
    let month = date.getMonth();
    let realMonth = month + 1;
    if (realMonth < 10) {
        realMonth = "0" + realMonth;
    
    }
    const [year, setYear] = useState(years);
    const [dia, setDia] = useState(days);
    const [mesSET, setMes] = useState(realMonth);

    let selectMonth = [];
    for (let i = 1; i < 13; i++) {
        if (i < 10) {
            i = "0" + i;
            selectMonth.push(i);
        } else {
            selectMonth.push(i);
        }
    }
    let selectYear = [];
    for (let i = 2002; i < 2025; i++) {
        selectYear.push(i);
    }

    return (
        <div className="container-fluid p-0 m-50">
            <div className="col-12 bg_admin"></div>
            <div className="container mt-2">
                <div className="row title-lines">
                    <div className="col-3"></div>
                    <div className="text-center col-4 col-lg-2 p-0">
                        Año
                    </div>
                    <div className="text-center col-4 col-lg-2 p-0">
                        Mes
                    </div>
                </div>
                <div className="row bg-white shadow">
                    <div className="col-2 col-lg-3 fs-3 text-end pe-3 d-flex align-items-center d-none d-lg-block">Buscar por fecha</div>
                    <div className="text-center col-4 col-lg-2 p-1">
                        <select className="form-select" name="year" aria-label="Default select example" defaultValue={years} onChange={e => setYear(e.target.value)}>
                            {
                                selectYear.map((index) => {
                                    return (
                                        <option key={index} name="promotions" value={index}>{index}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="text-center col-4 col-lg-2 p-1">
                        <select className="form-select" name="month" aria-label="Default select example" defaultValue={realMonth} onChange={e => setMes(e.target.value)}>
                            {
                                selectMonth.map((index) => {
                                    return (
                                        <option key={index} name="promotions" value={index}>{index}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="row">
                    {
                        store.noticias.map((item, index) => {
                            let yean = item.year;
                            let mes = item.mes;
                            if ( yean == year && mes == mesSET) {
                                return (
                                    <div key={index} className="col-6 p-1">
                                        <List_Noticias
                                            id={index}
                                            autor={item.autor}
                                            h1={item.h1}
                                            year={item.year}
                                            dia={item.dia}
                                            mes={item.mes}
                                        />
                                    </div>
                                );
                            }
                        })}

                </div>
            </div>
        </div>
    );
};
