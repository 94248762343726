import React, { useState, useContext, useEffect } from "react";

import { Redirect } from 'react-router-dom';
import { Context } from "../store/appContext";
import "../../node_modules/aos/dist/aos.css";
export const Noticias = () => {
    const { store } = useContext(Context);
    let date = new Date();
    let year_input = date.getFullYear();
    let mes_input = date.getMonth();
    let dia_input = date.getUTCDate();
    let roy = window.localStorage.getItem("my_token", JSON.stringify());
    if (!roy) {
        window.location.href = '/';
    } else {

    }
    let mesCorrecto = mes_input + 1;
    if (mesCorrecto < 10) {
        mesCorrecto = "0" + mesCorrecto;
    }

    const [autor, setautor] = useState("");
    const [top_head_line, settop_head_line] = useState("");
    const [deporte, setdeporte] = useState("");
    const [descripcion, setdescripcion] = useState("");
    const [year, setyear] = useState(year_input);
    const [mes, setmes] = useState(mesCorrecto);
    const [dia, setdia] = useState(dia_input);
    const [h1, seth1] = useState("");
    const [h2, seth2] = useState("");
    const [h3, seth3] = useState("");
    const [h4, seth4] = useState("");
    const [h5, seth5] = useState("");
    const [h6, seth6] = useState("");
    const [desarrollo1, setdesarrollo1] = useState("");
    const [desarrollo2, setdesarrollo2] = useState("");
    const [desarrollo3, setdesarrollo3] = useState("");
    const [desarrollo4, setdesarrollo4] = useState("");
    const [desarrollo5, setdesarrollo5] = useState("");
    const [desarrollo6, setdesarrollo6] = useState("");
    const [enlace_1, setenlace_1] = useState("");
    const [enlace_2, setenlace_2] = useState("");
    const [enlace_3, setenlace_3] = useState("");
    const [imagen_principal, setimagen_principal] = useState("");
    const [imagen_secundaria_1, setimagen_secundaria_1] = useState("");
    const [imagen_secundaria_2, setimagen_secundaria_2] = useState("");
    const [imagen_secundaria_3, setimagen_secundaria_3] = useState("");
    const [video_1, setvideo_1] = useState("");
    const [video_2, setvideo_2] = useState("");

    let actualizar = () => {
        document.getElementById("miFormNoticia").reset();
    }

    const [auth, setAuth] = useState(false);

    const crear = e => {
        e.preventDefault();
        actualizar();
        const body = {
            autor: autor,
            top_head_line: top_head_line,
            deporte: deporte,
            descripcion: descripcion,
            year: year,
            mes: mes,
            dia: dia,
            h1: h1,
            h2: h2,
            h3: h3,
            h4: h4,
            h5: h5,
            h6: h6,
            desarrollo1: desarrollo1,
            desarrollo2: desarrollo2,
            desarrollo3: desarrollo3,
            desarrollo4: desarrollo4,
            desarrollo5: desarrollo5,
            desarrollo6: desarrollo6,
            enlace_1: enlace_1,
            enlace_2: enlace_2,
            enlace_3: enlace_3,
            imagen_principal: imagen_principal,
            imagen_secundaria_1: imagen_secundaria_1,
            imagen_secundaria_2: imagen_secundaria_2,
            imagen_secundaria_3: imagen_secundaria_3,
            video_1: video_1,
            video_2: video_2,
        };

        fetch("https://noticiasdeportivas.herokuapp.com/noticias", {
            method: "POST",
            body: JSON.stringify(body),
            headers: { "Content-Type": "application/json" }
        })
            .then(res => res.json())
            .then(data => {
                sessionStorage.setItem("my_token", data.token);

                alert("Noticia se Creo");


            })
            .catch(err => console.log(err));
    };

    //select
    let selectYear = [];
    for (let i = 2022; i < 2051; i++) {
        selectYear.push(i);
    }
    let selectMonth = [];
    for (let i = 1; i < 13; i++) {
        if (i < 10) {
            i = "0" + i;
            selectMonth.push(i);
        } else {
            selectMonth.push(i);
        }
    }
    let selectDay = [];
    for (let i = 1; i < 32; i++) {
        if (i < 10) {
            i = "0" + i;
            selectDay.push(i);
        } else {
            selectDay.push(i);
        }
    }
    let selectWeek = [];
    for (let i = 1; i < 53; i++) {
        selectWeek.push(i);
    }
    let selectHour = [];
    for (let i = 1; i < 25; i++) {
        if (i < 10) {
            i = "0" + i;
            selectHour.push(i);
        } else {
            selectHour.push(i);
        }
    }
    let selectMin = [];
    for (let i = 0; i < 60; i++) {
        if (i < 10) {
            i = "0" + i;
            selectMin.push(i);
        } else {
            selectMin.push(i);
        }
    }
    let top_head_line_drop =["No", "Si"];

    useEffect(() => {
        const mensaje = document.getElementById('mensaje');
        const contador = document.getElementById('contador');
        mensaje.addEventListener('input', function (e) {
            const target = e.target;
            const longitudMax = target.getAttribute('maxLength');
            const longitudAct = target.value.length;
            contador.innerHTML = `${longitudAct}/${longitudMax}`;
        });
    })
    return (
        <div className="container-fluid p-0 m-0 accordion" id="gameCreate" >
            <div className="col-12 bg-title-edith d-flex justify-content-center align-items-center">
                <h1>Crear Noticia</h1>
            </div>
            <form onSubmit={crear} id="miFormNoticia">
                <div className="container g-0">
                    <div className="row g-0">
                        <div className="text-center col-1 p-1">
                            <div className="col-12 title-lines text-center">Año</div>
                            <select className="form-select shadow" name="year" aria-label="Ano" onChange={e => setyear(e.target.value)} defaultValue={year_input}>
                                {
                                    selectYear.map((index) => {
                                        return (
                                            <option key={index} name="ano" value={index} >{index}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="text-center col-1 p-1">
                            <div className="col-12 title-lines text-center">Mes</div>
                            <select className="form-select shadow" name="month" aria-label="Default select example" defaultValue={mesCorrecto} onChange={e => setmes(e.target.value)}>
                                {
                                    selectMonth.map((index) => {
                                        return (
                                            <option key={index} name="promotions" value={index}>{index}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="text-center col-1  p-1">
                            <div className="col-12 title-lines text-center">Día</div>
                            <select className="form-select shadow" name="day" aria-label="Default select example" defaultValue={dia_input} onChange={e => setdia(e.target.value)}>
                                {
                                    selectDay.map((index) => {
                                        return (
                                            <option key={index} name="promotions" value={index}>{index}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-2 p-1">
                            <div className="col-12 title-lines text-center">Autor</div>
                            <input type="text" className="form-control selectInner shadow" placeholder="Autor" name="setautor" onChange={e => setautor(e.target.value)} />
                        </div>
                        <div className="col-2 p-1">
                            <div className="col-12 title-lines text-center">Top Head Line</div>
                            <select className="form-select shadow" name="day" aria-label="Default select example" defaultValue="No" onChange={e => settop_head_line(e.target.value)}>
                                {
                                    top_head_line_drop.map((index) => {
                                        return (
                                            <option key={index} name="promotions" value={index}>{index}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-2 p-1">
                            <div className="col-12 title-lines text-center">Deporte</div>
                            <select className="form-select shadow" name="day" aria-label="Default select example" defaultValue="NFL" onChange={e => setdeporte(e.target.value)}>
                                {
                                    store.deportes.map((item,index) => {
                                        return (
                                            <option key={index} name="promotions" value={item.name}>{item.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-3  p-1">
                            <div className="col-12 title-lines text-center">Imagen Principal</div>
                            <input className="form-control selectInner shadow" type="text" placeholder="Imagen Principal" aria-label="setimagen_principal" onChange={e => setimagen_principal(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-12 shadow my-3">
                        <div className="col-12 title-lines text-center">Titulo</div>
                        <input className="form-control selectInner" type="text" placeholder="H1" aria-label="h1" onChange={e => seth1(e.target.value)} />
                    </div>
                    <div className="col-12 shadow my-3">
                        <div className="col-12 title-lines text-center">Descripción</div>
                        <textarea id="mensaje" className="form-control selectInner" type="text" placeholder="Max 500 caracteres" maxLength="500" aria-label="Descripcion" rows="3" onChange={e => setdescripcion(e.target.value)} />
                    </div>
                    <div id="contador">0/500</div>

     
                    <div className="col-12 shadow my-3">
                        <div className="col-12 title-lines text-center">Primera Parte</div>
                        <textarea id="mensaje" className="form-control selectInner" type="text" placeholder="Primera Parte" aria-label="setdesarrollo1" rows="10" onChange={e => setdesarrollo1(e.target.value)} />
                    </div>

                    <div className="col-12 shadow my-3">
                        <div className="col-12 title-lines text-center">Subtitulo</div>
                        <input className="form-control selectInner" type="text" placeholder="H2" aria-label="h2" onChange={e => seth2(e.target.value)} />
                    </div>
                    <div className="col-12 shadow my-3">
                        <div className="col-12 title-lines text-center">Segunda Parte</div>
                        <textarea id="mensaje" className="form-control selectInner" type="text" placeholder="Segunda Parte" aria-label="setdesarrollo2" rows="10" onChange={e => setdesarrollo2(e.target.value)} />
                    </div>

                    <div className="col-12 shadow my-3">
                        <div className="col-12 title-lines text-center">Subtitulo </div>
                        <input className="form-control selectInner" type="text" placeholder="H3" aria-label="h3" onChange={e => seth3(e.target.value)} />
                    </div>
                    <div className="col-12 shadow my-3">
                        <div className="col-12 title-lines text-center">Tercera Parte</div>
                        <textarea id="mensaje" className="form-control selectInner" type="text" placeholder="Tercera Parte" aria-label="setdesarrollo3" rows="10" onChange={e => setdesarrollo3(e.target.value)} />
                    </div>

                    <div className="col-12 shadow my-3">
                        <div className="col-12 title-lines text-center">Subtitulo </div>
                        <input className="form-control selectInner" type="text" placeholder="H4" aria-label="h4" onChange={e => seth4(e.target.value)} />
                    </div>
                    <div className="col-12 shadow my-3">
                        <div className="col-12 title-lines text-center">Cuarta Parte</div>
                        <textarea id="mensaje" className="form-control selectInner" type="text" placeholder="Cuarta Parte" aria-label="setdesarrollo4" rows="10" onChange={e => setdesarrollo4(e.target.value)} />
                    </div>

                    <div className="col-12 shadow my-3">
                        <div className="col-12 title-lines text-center">Subtitulo </div>
                        <input className="form-control selectInner" type="text" placeholder="H5" aria-label="h5" onChange={e => seth5(e.target.value)} />
                    </div>
                    <div className="col-12 shadow my-3">
                        <div className="col-12 title-lines text-center">Quinta Parte</div>
                        <textarea id="mensaje" className="form-control selectInner" type="text" placeholder="Quinta Parte" aria-label="setdesarrollo5" rows="10" onChange={e => setdesarrollo5(e.target.value)} />
                    </div>

                    <div className="col-12 shadow my-3">
                        <div className="col-12 title-lines text-center">Subtitulo </div>
                        <input className="form-control selectInner" type="text" placeholder="H6" aria-label="h6" onChange={e => seth6(e.target.value)} />
                    </div>
                    <div className="col-12 shadow my-3">
                        <div className="col-12 title-lines text-center">Sexta Parte</div>
                        <textarea id="mensaje" className="form-control selectInner" type="text" placeholder="Sexta Parte" aria-label="setdesarrollo6" rows="10" onChange={e => setdesarrollo6(e.target.value)} />
                    </div>

                    <div className="row g-0">
                        <div className="col-4 shadow my-3">
                            <div className="col-12 title-lines text-center">Enlace 1 </div>
                            <input className="form-control selectInner" type="text" placeholder="Enlace" aria-label="Enlace" onChange={e => setenlace_1(e.target.value)} />
                        </div>
                        <div className="col-4 shadow my-3">
                            <div className="col-12 title-lines text-center">Enlace 2 </div>
                            <input className="form-control selectInner" type="text" placeholder="Enlace" aria-label="Enlace" onChange={e => setenlace_2(e.target.value)} />
                        </div>
                        <div className="col-4 shadow my-3">
                            <div className="col-12 title-lines text-center">Enlace 3 </div>
                            <input className="form-control selectInner" type="text" placeholder="Enlace" aria-label="Enlace" onChange={e => setenlace_3(e.target.value)} />
                        </div>
                    </div>

                    <div className="row g-0">
                        <div className="col-4 shadow my-3">
                            <div className="col-12 title-lines text-center">Imagen Secundaria</div>
                            <input className="form-control selectInner" type="text" placeholder="Imagen Secundaria 1" aria-label="imagen_secundaria" onChange={e => setimagen_secundaria_1(e.target.value)} />
                        </div>
                        <div className="col-4 shadow my-3">
                            <div className="col-12 title-lines text-center">Imagen Secundaria</div>
                            <input className="form-control selectInner" type="text" placeholder="Imagen Secundaria 2" aria-label="imagen_secundaria" onChange={e => setimagen_secundaria_2(e.target.value)} />
                        </div>
                        <div className="col-4 shadow my-3">
                            <div className="col-12 title-lines text-center">Imagen Secundaria</div>
                            <input className="form-control selectInner" type="text" placeholder="Imagen Secundaria 3" aria-label="imagen_secundaria" onChange={e => setimagen_secundaria_3(e.target.value)} />
                        </div>
                    </div>
                    
                    <div className="row g-0">
                        <div className="col-6 shadow my-3">
                            <div className="col-12 title-lines text-center">Enlace a Video</div>
                            <input className="form-control selectInner" type="text" placeholder="Enlace a Video 1" aria-label="imagen_secundaria" onChange={e => setvideo_1(e.target.value)} />
                        </div>
                        <div className="col-6 shadow my-3">
                            <div className="col-12 title-lines text-center">Enlace a Video</div>
                            <input className="form-control selectInner" type="text" placeholder="Enlace a Video 2" aria-label="imagen_secundaria" onChange={e => setvideo_2(e.target.value)} />
                        </div>
                    </div>
                </div>
                <div className="col-10 text-end py-3">
                    <button type="submit" className="btn btn-danger">Create</button>
                </div>
            </form>
            {auth ? <Redirect to="/admin" /> : null}
        </div>
    )
}