import { useParams, Redirect } from 'react-router-dom';
import React, { useState, useContext, useEffect } from "react";
import { Context } from "../store/appContext";
import "../../node_modules/aos/dist/aos.css";
import { HashLink } from 'react-router-hash-link';
export const Edith_Noticias = (props) => {
    const params = useParams();
    const { store } = useContext(Context);

    let roy = window.localStorage.getItem("my_token", JSON.stringify());
    if (!roy) {
        window.location.href = '/';
    } else {

    }

    const [autor, setautor] = useState(store.noticias[params.theid] && store.noticias[params.theid].autor);
    const [top_head_line, settop_head_line] = useState(store.noticias[params.theid] && store.noticias[params.theid].top_head_line);
    const [deporte, setdeporte] = useState(store.noticias[params.theid] && store.noticias[params.theid].deporte);
    const [descripcion, setdescripcion] = useState(store.noticias[params.theid] && store.noticias[params.theid].descripcion);
    const [year, setyear] = useState(store.noticias[params.theid] && store.noticias[params.theid].year);
    const [mes, setmes] = useState(store.noticias[params.theid] && store.noticias[params.theid].mes);
    const [dia, setdia] = useState(store.noticias[params.theid] && store.noticias[params.theid].dia);
    const [h1, seth1] = useState(store.noticias[params.theid] && store.noticias[params.theid].h1);
    const [h2, seth2] = useState(store.noticias[params.theid] && store.noticias[params.theid].h2);
    const [h3, seth3] = useState(store.noticias[params.theid] && store.noticias[params.theid].h3);
    const [h4, seth4] = useState(store.noticias[params.theid] && store.noticias[params.theid].h4);
    const [h5, seth5] = useState(store.noticias[params.theid] && store.noticias[params.theid].h5);
    const [h6, seth6] = useState(store.noticias[params.theid] && store.noticias[params.theid].h6);
    const [desarrollo1, setdesarrollo1] = useState(store.noticias[params.theid] && store.noticias[params.theid].desarrollo1);
    const [desarrollo2, setdesarrollo2] = useState(store.noticias[params.theid] && store.noticias[params.theid].desarrollo2);
    const [desarrollo3, setdesarrollo3] = useState(store.noticias[params.theid] && store.noticias[params.theid].desarrollo3);
    const [desarrollo4, setdesarrollo4] = useState(store.noticias[params.theid] && store.noticias[params.theid].desarrollo4);
    const [desarrollo5, setdesarrollo5] = useState(store.noticias[params.theid] && store.noticias[params.theid].desarrollo5);
    const [desarrollo6, setdesarrollo6] = useState(store.noticias[params.theid] && store.noticias[params.theid].desarrollo6);
    const [enlace_1, setenlace_1] = useState(store.noticias[params.theid] && store.noticias[params.theid].enlace_1);
    const [enlace_2, setenlace_2] = useState(store.noticias[params.theid] && store.noticias[params.theid].enlace_2);
    const [enlace_3, setenlace_3] = useState(store.noticias[params.theid] && store.noticias[params.theid].enlace_3);
    const [imagen_principal, setimagen_principal] = useState(store.noticias[params.theid] && store.noticias[params.theid].imagen_principal);
    const [imagen_secundaria_1, setimagen_secundaria_1] = useState(store.noticias[params.theid] && store.noticias[params.theid].imagen_secundaria_1);
    const [imagen_secundaria_2, setimagen_secundaria_2] = useState(store.noticias[params.theid] && store.noticias[params.theid].imagen_secundaria_2);
    const [imagen_secundaria_3, setimagen_secundaria_3] = useState(store.noticias[params.theid] && store.noticias[params.theid].imagen_secundaria_3);
    const [video_1, setvideo_1] = useState(store.noticias[params.theid] && store.noticias[params.theid].video_1);
    const [video_2, setvideo_2] = useState(store.noticias[params.theid] && store.noticias[params.theid].video_2);


    const [auth, setAuth] = useState(false);
    let actualizar = () => {
        setTimeout(function () { window.location.reload(true); }, 800);

    }
    const crear = e => {
        e.preventDefault();
        const body = {
            autor: autor,
            top_head_line: top_head_line,
            deporte: deporte,
            descripcion: descripcion,
            year: year,
            mes: mes,
            dia: dia,
            h1: h1,
            h2: h2,
            h3: h3,
            h4: h4,
            h5: h5,
            h6: h6,
            desarrollo1: desarrollo1,
            desarrollo2: desarrollo2,
            desarrollo3: desarrollo3,
            desarrollo4: desarrollo4,
            desarrollo5: desarrollo5,
            desarrollo6: desarrollo6,
            enlace_1: enlace_1,
            enlace_2: enlace_2,
            enlace_3: enlace_3,
            imagen_principal: imagen_principal,
            imagen_secundaria_1: imagen_secundaria_1,
            imagen_secundaria_2: imagen_secundaria_2,
            imagen_secundaria_3: imagen_secundaria_3,
            video_1: video_1,
            video_2: video_2,
        };


        fetch("https://noticiasdeportivas.herokuapp.com/noticias/" + store.noticias[params.theid].id, {
            method: "PUT",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "https://localhost:3000/",
                "Access-Control-Allow-Methods": "PUT"
            }
        })
            .then(res => res.json())
            .then(data => {
                sessionStorage.setItem("my_token", data.token);

                alert("Noticia se Actualizó");
                setAuth(true);
                actualizar();
            })
            .catch(err => console.log(err));

    };
    const delet = e => {
        fetch("https://noticiasdeportivas.herokuapp.com/noticias/" + store.noticias[params.theid].id, {
            method: "DELETE",
            headers: { "Content-Type": "application/json" }
        })
            .then(res => res.json())
            .catch(err => console.log(err));
        setAuth(true);
        actualizar();
    };

    //select
    let selectYear = [];
    for (let i = 2022; i < 2050; i++) {
        selectYear.push(i);
    }
    let selectMonth = [];
    for (let i = 0; i < 24; i++) {
        if (i < 10) {
            i = "0" + i;
            selectMonth.push(i);
        } else {
            selectMonth.push(i);
        }
    }
    let selectDay = [];
    for (let i = 1; i < 32; i++) {
        if (i < 10) {
            i = "0" + i;
            selectDay.push(i);
        } else {
            selectDay.push(i);
        }
    }
    let selectWeek = [];
    for (let i = 1; i < 53; i++) {
        selectWeek.push(i);
    }
    let selectHour = [];
    for (let i = 1; i < 25; i++) {
        if (i < 10) {
            i = "0" + i;
            selectHour.push(i);
        } else {
            selectHour.push(i);
        }
    }
    let selectMin = [];
    for (let i = 1; i < 60; i++) {
        if (i < 10) {
            i = "0" + i;
            selectMin.push(i);
        } else {
            selectMin.push(i);
        }
    }
    let top_head_line_drop =["No", "Si"];
    let deporte_drop =["NFL","NBA","MLB","NHL","SOCCER","GOLF","BOX","MMA","NASCAR","MOTO GP"]
    return (
        <div className="container-fluid accordion" id="gameEdith" >
            <div className="col-12 p-3 bg-title-edith my-2 d-flex justify-content-center align-items-center">
                <div className="row g-0 w-100 fs-1">
                    <div className="col-6 text-center">
                        Editar Noticia
                    </div>
                    <div className="col-6">
                        {store.noticias[params.theid] && store.noticias[params.theid].h1}
                    </div>
                </div>
            </div>
            <form onSubmit={crear} >
                <div className="container g-0">
                    <div className="row g-0">
                        <div className="text-center col-1 p-1">
                            <div className="col-12 title-lines text-center">Año</div>
                            <select className="form-select shadow" name="year" aria-label="Ano" onChange={e => setyear(e.target.value)} defaultValue={store.noticias[params.theid] && store.noticias[params.theid].mes}>
                                {
                                    selectYear.map((index) => {
                                        return (
                                            <option key={index} name="ano" value={index} >{index}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="text-center col-1 p-1">
                            <div className="col-12 title-lines text-center">Mes</div>
                            <select className="form-select shadow" name="month" aria-label="Default select example" defaultValue={store.noticias[params.theid] && store.noticias[params.theid].mes} onChange={e => setmes(e.target.value)}>
                                {
                                    selectMonth.map((index) => {
                                        return (
                                            <option key={index} name="promotions" value={index}>{index}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="text-center col-1  p-1">
                            <div className="col-12 title-lines text-center">Día</div>
                            <select className="form-select shadow" name="day" aria-label="Default select example" defaultValue={store.noticias[params.theid] && store.noticias[params.theid].dia} onChange={e => setdia(e.target.value)}>
                                {
                                    selectDay.map((index) => {
                                        return (
                                            <option key={index} name="promotions" value={index}>{index}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-2 p-1">
                            <div className="col-12 title-lines text-center">Autor</div>
                            <input type="text" className="form-control selectInner shadow" placeholder="Autor" defaultValue={store.noticias[params.theid] && store.noticias[params.theid].autor} name="setautor" onChange={e => setautor(e.target.value)} />
                        </div>
                        <div className="col-2 p-1">
                            <div className="col-12 title-lines text-center">Top Head Line</div>
                            <select className="form-select shadow" name="day" aria-label="Default select example" defaultValue={store.noticias[params.theid] && store.noticias[params.theid].top_head_line} onChange={e => settop_head_line(e.target.value)}>
                                {
                                    top_head_line_drop.map((index) => {
                                        return (
                                            <option key={index} name="promotions" value={index}>{index}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-1 p-1">
                            <div className="col-12 title-lines text-center">Deporte</div>
                            <select className="form-select shadow" name="day" aria-label="Default select example" defaultValue={store.noticias[params.theid] && store.noticias[params.theid].deporte} onChange={e => setdeporte(e.target.value)}>
                                {
                                    deporte_drop.map((index) => {
                                        return (
                                            <option key={index} name="promotions" value={index}>{index}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-4  p-1">
                            <div className="col-12 title-lines text-center">Imagen Principal</div>
                            <input className="form-control selectInner shadow" type="text" placeholder="Imagen Principal" defaultValue={store.noticias[params.theid] && store.noticias[params.theid].imagen_principal} aria-label="setimagen_principal" onChange={e => setimagen_principal(e.target.value)} />
                        </div>
                        <div className="col-3 mx-auto p-1">
                            <div className="col-12 title-lines text-center">Imagen Principal</div>
                            <img className="img-fluid" src={store.noticias[params.theid] && store.noticias[params.theid].imagen_principal}></img>
                        </div>
                    </div>
                    <div className="col-12 shadow my-3">
                        <div className="col-12 title-lines text-center">Descripción</div>
                        <textarea id="mensaje" className="form-control selectInner" type="text" placeholder="Max 200 caracteres" maxLength="200" aria-label="Descripcion" rows="3" defaultValue={store.noticias[params.theid] && store.noticias[params.theid].descripcion} onChange={e => setdescripcion(e.target.value)} />
                    </div>
                    <div id="contador">0/200</div>

                    <div className="col-12 shadow my-3">
                        <div className="col-12 title-lines text-center">Titulo</div>
                        <input className="form-control selectInner" type="text" placeholder="H1" aria-label="h1" defaultValue={store.noticias[params.theid] && store.noticias[params.theid].h1} onChange={e => seth1(e.target.value)} />
                    </div>
                    <div className="col-12 shadow my-3">
                        <div className="col-12 title-lines text-center">Primera Parte</div>
                        <textarea id="mensaje" className="form-control selectInner" type="text" placeholder="Primera Parte" aria-label="setdesarrollo1" rows="10" defaultValue={store.noticias[params.theid] && store.noticias[params.theid].desarrollo1} onChange={e => setdesarrollo1(e.target.value)} />
                    </div>

                    <div className="col-12 shadow my-3">
                        <div className="col-12 title-lines text-center">Subtitulo</div>
                        <input className="form-control selectInner" type="text" placeholder="H2" aria-label="h2" defaultValue={store.noticias[params.theid] && store.noticias[params.theid].h2} onChange={e => seth2(e.target.value)} />
                    </div>
                    <div className="col-12 shadow my-3">
                        <div className="col-12 title-lines text-center">Segunda Parte</div>
                        <textarea id="mensaje" className="form-control selectInner" type="text" placeholder="Segunda Parte" aria-label="setdesarrollo2" rows="10" defaultValue={store.noticias[params.theid] && store.noticias[params.theid].desarrollo2} onChange={e => setdesarrollo2(e.target.value)} />
                    </div>

                    <div className="col-12 shadow my-3">
                        <div className="col-12 title-lines text-center">Subtitulo </div>
                        <input className="form-control selectInner" type="text" placeholder="H3" aria-label="h3" defaultValue={store.noticias[params.theid] && store.noticias[params.theid].h3} onChange={e => seth3(e.target.value)} />
                    </div>
                    <div className="col-12 shadow my-3">
                        <div className="col-12 title-lines text-center">Tercera Parte</div>
                        <textarea id="mensaje" className="form-control selectInner" type="text" placeholder="Tercera Parte" aria-label="setdesarrollo3" rows="10" defaultValue={store.noticias[params.theid] && store.noticias[params.theid].desarrollo3} onChange={e => setdesarrollo3(e.target.value)} />
                    </div>

                    <div className="col-12 shadow my-3">
                        <div className="col-12 title-lines text-center">Subtitulo </div>
                        <input className="form-control selectInner" type="text" placeholder="H4" aria-label="h4" defaultValue={store.noticias[params.theid] && store.noticias[params.theid].h4} onChange={e => seth4(e.target.value)} />
                    </div>
                    <div className="col-12 shadow my-3">
                        <div className="col-12 title-lines text-center">Cuarta Parte</div>
                        <textarea id="mensaje" className="form-control selectInner" type="text" placeholder="Cuarta Parte" aria-label="setdesarrollo4" rows="10" defaultValue={store.noticias[params.theid] && store.noticias[params.theid].desarrollo4} onChange={e => setdesarrollo4(e.target.value)} />
                    </div>

                    <div className="col-12 shadow my-3">
                        <div className="col-12 title-lines text-center">Subtitulo </div>
                        <input className="form-control selectInner" type="text" placeholder="H5" aria-label="h5" defaultValue={store.noticias[params.theid] && store.noticias[params.theid].h5} onChange={e => seth5(e.target.value)} />
                    </div>
                    <div className="col-12 shadow my-3">
                        <div className="col-12 title-lines text-center">Quinta Parte</div>
                        <textarea id="mensaje" className="form-control selectInner" type="text" placeholder="Quinta Parte" aria-label="setdesarrollo5" rows="10" defaultValue={store.noticias[params.theid] && store.noticias[params.theid].desarrollo5} onChange={e => setdesarrollo5(e.target.value)} />
                    </div>

                    <div className="col-12 shadow my-3">
                        <div className="col-12 title-lines text-center">Subtitulo </div>
                        <input className="form-control selectInner" type="text" placeholder="H6" aria-label="h6" defaultValue={store.noticias[params.theid] && store.noticias[params.theid].h6} onChange={e => seth6(e.target.value)} />
                    </div>
                    <div className="col-12 shadow my-3">
                        <div className="col-12 title-lines text-center">Sexta Parte</div>
                        <textarea id="mensaje" className="form-control selectInner" type="text" placeholder="Sexta Parte" aria-label="setdesarrollo6" rows="10" defaultValue={store.noticias[params.theid] && store.noticias[params.theid].desarrollo6} onChange={e => setdesarrollo6(e.target.value)} />
                    </div>

                    <div className="row g-0">
                        <div className="col-4 shadow my-3">
                            <div className="col-12 title-lines text-center">Enlace 1 </div>
                            <input className="form-control selectInner" type="text" placeholder="Enlace" aria-label="Enlace" defaultValue={store.noticias[params.theid] && store.noticias[params.theid].enlace_1} onChange={e => setenlace_1(e.target.value)} />
                        </div>
                        <div className="col-4 shadow my-3">
                            <div className="col-12 title-lines text-center">Enlace 2 </div>
                            <input className="form-control selectInner" type="text" placeholder="Enlace" aria-label="Enlace" defaultValue={store.noticias[params.theid] && store.noticias[params.theid].enlace_2} onChange={e => setenlace_2(e.target.value)} />
                        </div>
                        <div className="col-4 shadow my-3">
                            <div className="col-12 title-lines text-center">Enlace 3 </div>
                            <input className="form-control selectInner" type="text" placeholder="Enlace" aria-label="Enlace" defaultValue={store.noticias[params.theid] && store.noticias[params.theid].enlace_3} onChange={e => setenlace_3(e.target.value)} />
                        </div>
                    </div>

                    <div className="row g-0">
                        <div className="col-4 shadow my-3">
                            <div className="col-12 title-lines text-center">Imagen Secundaria</div>
                            <input className="form-control selectInner" type="text" placeholder="Imagen Secundaria 1" aria-label="imagen_secundaria" defaultValue={store.noticias[params.theid] && store.noticias[params.theid].imagen_secundaria_1} onChange={e => setimagen_secundaria_1(e.target.value)} />
                            <img className="img-fluid" src={store.noticias[params.theid] && store.noticias[params.theid].imagen_secundaria_1}></img>
                        </div>
                        <div className="col-4 shadow my-3">
                            <div className="col-12 title-lines text-center">Imagen Secundaria</div>
                            <input className="form-control selectInner" type="text" placeholder="Imagen Secundaria 2" aria-label="imagen_secundaria" defaultValue={store.noticias[params.theid] && store.noticias[params.theid].imagen_secundaria_2} onChange={e => setimagen_secundaria_2(e.target.value)} />
                            <img className="img-fluid" src={store.noticias[params.theid] && store.noticias[params.theid].imagen_secundaria_2}></img>
                        </div>
                        <div className="col-4 shadow my-3">
                            <div className="col-12 title-lines text-center">Imagen Secundaria</div>
                            <input className="form-control selectInner" type="text" placeholder="Imagen Secundaria 3" aria-label="imagen_secundaria" defaultValue={store.noticias[params.theid] && store.noticias[params.theid].imagen_secundaria_3} onChange={e => setimagen_secundaria_3(e.target.value)} />
                            <img className="img-fluid" src={store.noticias[params.theid] && store.noticias[params.theid].imagen_secundaria_3}></img>
                        </div>
                    </div>

                    <div className="row g-0">
                        <div className="col-6 shadow my-3">
                            <div className="col-12 title-lines text-center">Enlace a Video</div>
                            <input className="form-control selectInner" type="text" placeholder="Enlace a Video 1" aria-label="imagen_secundaria" defaultValue={store.noticias[params.theid] && store.noticias[params.theid].video_1} onChange={e => setvideo_1(e.target.value)} />

                            <iframe width="560" height="315" src={store.noticias[params.theid] && store.noticias[params.theid].video_1} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                        </div>
                        <div className="col-6 shadow my-3">
                            <div className="col-12 title-lines text-center">Enlace a Video</div>
                            <input className="form-control selectInner" type="text" placeholder="Enlace a Video 2" aria-label="imagen_secundaria" defaultValue={store.noticias[params.theid] && store.noticias[params.theid].video_2} onChange={e => setvideo_2(e.target.value)} />

                            <iframe width="560" height="315" src={store.noticias[params.theid] && store.noticias[params.theid].video_2} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
                <div className="row g-0">
                    <div className="col-4 p-3 text-end">
                        <HashLink className="btn btn-secondary  text-white" to="/admin">Back to Admin</HashLink>
                    </div>
                    <div className="col-4 text-center p-3">
                        <button type="submit" className="btn btn-success">Edit</button>
                    </div>
                    <div className="col-4 text-start p-3">
                        <div data-bs-toggle="modal" data-bs-target="#delete" className="btn btn-danger">Delet</div>
                    </div>
                </div>
            </form>
            <div className="modal fade" id="delete" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="deleteLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content overflow-hidden">
                        <div className="col-12 text-center text-white bg-danger text-uppercase fs-5 py-3">
                            <i className="fas fa-exclamation-triangle fs-1"></i><br />are you sure you want to delete the match
                        </div>
                        <div className="row g-0">
                            <div className="col-6 p-2 text-center">
                                <button className="btn btn-danger" onClick={delet} data-bs-dismiss="modal">Yes Delete</button>
                                {auth ? <Redirect to="/admin" /> : null}
                            </div>
                            <div className="col-6 p-2 text-center">
                                <button type="button" className="btn btn-secondary text-white" data-bs-dismiss="modal">No</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}