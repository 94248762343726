import { BrowserRouter, Route, Switch } from "react-router-dom";
import injectContext from "./store/appContext";

/* pages*/
import { Login } from "./pages/login";
import { Admin } from "./pages/admin";
import { Edith_Noticias } from "./noticias/edtihNoticias";
import 	{ Noticias } from "./noticias/noticias";
import { Noticias_Skins } from "./noticias_skins/noticias_skins";
import { Edith_Noticias_Skins } from "./noticias_skins/edtihNoticias_skins";
import { Noticias_Skins_Creadas } from "./pages/noticias_skin_creadas";
import { Skins } from "./skin/skins";
import { Edith_Skins } from "./skin/edtih_skins";
import { Lista_skins } from "./pages/lista_skins";
import { Deporte } from "./deportes/deporte";
import { Edith_Deporte } from "./deportes/edtih_deporte";
import { Lista_Deportes } from "./pages/lista_deportes";



/*components*/
import { Header } from "./components/header";
import { Footer } from "./components/footer";

function App() {
	return (
		<div className="App">
			<BrowserRouter>
				<Header />
				<Switch>
					<Route exact path="/login" component={Login} />
					<Route exact path="/" component={Login} />
					<Route exact path="/admin" component={Admin} />
					<Route exact path="/noticias" component={Noticias} />
					<Route exact path="/noticias_skin_creadas" component={Noticias_Skins_Creadas} />
					<Route exact path="/edtihNoticias/:theid" component={Edith_Noticias} />
					<Route exact path="/noticias_skins" component={Noticias_Skins} />
					<Route exact path="/edtihNoticias_skins/:theid" component={Edith_Noticias_Skins} />
					<Route exact path="/skins" component={Skins} />
					<Route exact path="/edtih_skins/:theid" component={Edith_Skins} />
					<Route exact path="/lista_skins" component={Lista_skins} />
					<Route exact path="/deporte" component={Deporte} />
					<Route exact path="/edtih_deporte/:theid" component={Edith_Deporte} />
					<Route exact path="/lista_deportes" component={Lista_Deportes} />
					<Route><h1>404</h1> </Route>
				</Switch>
				<Footer />
			</BrowserRouter>
		</div>
	);
};

export default injectContext(App);
