const getState = ({ getStore, getActions, setStore }) => {
	return {
		store: {
			noticias: [],
			noticias_skins: [],
			skins:[],
			deportes:[],
			//name temporal
			username_temp: "-",

		},
		actions: {
			loadNoticias: async () => {
				const url = "https://noticiasdeportivas.herokuapp.com/noticias";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ noticias: results });
			},
			loadNoticias_skins: async () => {
				const url = "https://noticiasdeportivas.herokuapp.com/noticias_skins";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ noticias_skins: results });
			},
			load_Skins: async () => {
				const url = "https://noticiasdeportivas.herokuapp.com/skin";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ skins: results });
			},
			load_Deportes: async () => {
				const url = "https://noticiasdeportivas.herokuapp.com/deporte";
				const response = await fetch(url);
				const results = await response.json();
				setStore({ deportes: results });
			},
			//name
			changename: username => {
				setStore({ username_temp: username });
			}
		}
	};
};

export default getState;
