
import React, { useState, useContext } from "react";
import { Context } from "../store/appContext";

import "../../node_modules/aos/dist/aos.css";
import List_Skins from "../skin/list_skins";

export const Lista_skins = () => {
    const { store, actions } = useContext(Context);
    let roy = window.localStorage.getItem("my_token", JSON.stringify());
    if (!roy) {
        window.location.href = '/';
    } else {

    }

    return (
        <div className="container-fluid p-0 m-50">
            <div className="col-12 bg-title-edith d-flex justify-content-center align-items-center">
                <h1>Lista de Skins</h1>
            </div>
            <div className="container mt-2">
                <div className="row">
                    {
                        store.skins.map((item, index) => {
                                return (
                                    <div key={index} className="col-3 p-1">
                                        <List_Skins
                                            id={index}
                                            name={item.name}
                                        />
                                    </div>
                                );
                        })}
                </div>
            </div>
        </div>
    );
};
