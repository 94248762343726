import React, { useState, useContext, useEffect } from "react";

import { Redirect } from 'react-router-dom';
import { Context } from "../store/appContext";
import "../../node_modules/aos/dist/aos.css";
export const Noticias_Skins = () => {
    const { store } = useContext(Context);
    let date = new Date();
    let year_input = date.getFullYear();
    let mes_input = date.getMonth();
    let dia_input = date.getUTCDate();
    let roy = window.localStorage.getItem("my_token", JSON.stringify());
    if (!roy) {
        window.location.href = '/';
    } else {

    }
    let mesCorrecto = mes_input + 1;
    if (mesCorrecto < 10) {
        mesCorrecto = "0" + mesCorrecto;
    }
    const [descripcion, setdescripcion] = useState("");
    const [year, setyear] = useState(year_input);
    const [mes, setmes] = useState(mesCorrecto);
    const [dia, setdia] = useState(dia_input);
    const [h1, seth1] = useState("");
    const [skin, setSkin] = useState("global_skin");
    const [imagen_principal, setImagen_principal] = useState("");

    let actualizar = () => {
        document.getElementById("miFormNoticia").reset();
    }

    const [auth, setAuth] = useState(false);

    const crear = e => {
        e.preventDefault();
        actualizar();
        const body = {
            skin: skin,
            descripcion: descripcion,
            year: year,
            mes: mes,
            dia: dia,
            h1: h1,
            imagen_principal: imagen_principal

        };

        fetch("https://noticiasdeportivas.herokuapp.com/noticias_skins", {
            method: "POST",
            body: JSON.stringify(body),
            headers: { "Content-Type": "application/json" }
        })
            .then(res => res.json())
            .then(data => {
                sessionStorage.setItem("my_token", data.token);

                alert("Noticia se Creo");


            })
            .catch(err => console.log(err));
    };

    //select
    let selectYear = [];
    for (let i = 2022; i < 2051; i++) {
        selectYear.push(i);
    }
    let selectMonth = [];
    for (let i = 1; i < 13; i++) {
        if (i < 10) {
            i = "0" + i;
            selectMonth.push(i);
        } else {
            selectMonth.push(i);
        }
    }
    let selectDay = [];
    for (let i = 1; i < 32; i++) {
        if (i < 10) {
            i = "0" + i;
            selectDay.push(i);
        } else {
            selectDay.push(i);
        }
    }

    useEffect(() => {
        const mensaje = document.getElementById('mensaje');
        const contador = document.getElementById('contador');
        mensaje.addEventListener('input', function (e) {
            const target = e.target;
            const longitudMax = target.getAttribute('maxLength');
            const longitudAct = target.value.length;
            contador.innerHTML = `${longitudAct}/${longitudMax}`;
        });
    })
    return (
        <div className="container-fluid p-0 m-0 accordion" id="gameCreate" >
            <div className="col-12 bg-title-edith d-flex justify-content-center align-items-center">
                <h1>Crear Noticia para Skin</h1>
            </div>
            <form onSubmit={crear} id="miFormNoticia">
                <div className="container g-0">
                    <div className="row g-0">
                        <div className="text-center col-1 p-1">
                            <div className="col-12 title-lines text-center">Año</div>
                            <select className="form-select shadow" name="year" aria-label="Ano" onChange={e => setyear(e.target.value)} defaultValue={year_input}>
                                {
                                    selectYear.map((index) => {
                                        return (
                                            <option key={index} name="ano" value={index} >{index}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="text-center col-1 p-1">
                            <div className="col-12 title-lines text-center">Mes</div>
                            <select className="form-select shadow" name="month" aria-label="Default select example" defaultValue={mesCorrecto} onChange={e => setmes(e.target.value)}>
                                {
                                    selectMonth.map((index) => {
                                        return (
                                            <option key={index} name="promotions" value={index}>{index}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="text-center col-1  p-1">
                            <div className="col-12 title-lines text-center">Día</div>
                            <select className="form-select shadow" name="day" aria-label="Default select example" defaultValue={dia_input} onChange={e => setdia(e.target.value)}>
                                {
                                    selectDay.map((index) => {
                                        return (
                                            <option key={index} name="promotions" value={index}>{index}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="text-center col-2 p-1">
                            <div className="col-12 title-lines text-center">Skin</div>
                            <select className="form-select selectInner" name="week" aria-label="Default select example" defaultValue="global_skin" onChange={e => setSkin(e.target.value)}>
                                {
                                    store.skins.map((item, index) => {
                                        return (
                                            <option key={index} name="promotions" value={item.name}>{item.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-4 p-1">
                            <div className="col-12 title-lines text-center">Titulo</div>
                            <input className="form-control selectInner" type="text" placeholder="H1" aria-label="h1" onChange={e => seth1(e.target.value)} />
                        </div>
                        <div className="col-3 p-1">
                            <div className="col-12 title-lines text-center">Imagen</div>
                            <input className="form-control selectInner" type="text" placeholder="H1" aria-label="h1" onChange={e => setImagen_principal(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-12 shadow my-3">
                        <div className="col-12 title-lines text-center">Descripción</div>
                        <textarea id="mensaje" className="form-control selectInner" type="text" placeholder="Max 500 caracteres" maxLength="500" aria-label="Descripcion" rows="3" onChange={e => setdescripcion(e.target.value)} />
                    </div>
                    <div id="contador">0/500</div>
                </div>
                <div className="col-10 text-end py-3">
                    <button type="submit" className="btn btn-danger">Create</button>
                </div>
            </form>
            {auth ? <Redirect to="/admin" /> : null}
        </div>
    )
}