import React, { useState, useContext, useEffect } from "react";
import { Context } from "../store/appContext";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const List_Skins = props => {
    const { store } = useContext(Context);

    let roy = window.localStorage.getItem("my_token", JSON.stringify());
    if (!roy) {
        window.location.href = '/';
    } else {

    }

    return (
        <div className="row g-0 p-1">
            <div className="col-12 p-0 m-3 shadow">
                <div className="row g-0 text-center title-lines">
                    <div className="col-12 lines">Name</div>
                </div>
                <div className="row g-0 text-center bg-white">
                    <div className="col-12 lines"><Link to={`/edtih_skins/${props.id}`}>{props.name}</Link></div>
                </div>
            </div>
        </div>
    );
};
List_Skins.propTypes = {
    id: PropTypes.number,
    name: PropTypes.string,
};
export default List_Skins;
