import React, { useState, useContext, useEffect } from "react";
import { Context } from "../store/appContext";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const List_Noticias = props => {
    const { store } = useContext(Context);

    let roy = window.localStorage.getItem("my_token", JSON.stringify());
    if (!roy) {
        window.location.href = '/';
    } else {

    }

    return (
        <div className="row g-0">
            <div className="col-12 p-0 shadow">
                <div className="row g-0 text-center title-lines">
                    <div className="col-4 lines">Autor</div>
                    <div className="col-6 lines">Titulo</div>
                    <div className="col-2 lines">Fecha</div>
                </div>
                <div className="row g-0 text-center bg-white">
                    <div className="col-4 lines">{props.autor}</div>
                    <div className="col-6 lines"><Link to={`/edtihNoticias/${props.id}`}>{props.h1}</Link></div>
                    <div className="col-2 lines">{props.dia}-{props.mes}-{props.year}</div>
                </div>

            </div>
        </div>
    );
};
List_Noticias.propTypes = {
    id: PropTypes.number,
    autor: PropTypes.number,
    h1: PropTypes.string,
    dia: PropTypes.string,
    mes: PropTypes.string,
    year: PropTypes.string,
};
export default List_Noticias;
