import React, { useState, useContext, useEffect } from "react";

import { Redirect } from 'react-router-dom';
import { Context } from "../store/appContext";
import "../../node_modules/aos/dist/aos.css";
export const Deporte = () => {
    const { store } = useContext(Context);
    let roy = window.localStorage.getItem("my_token", JSON.stringify());
    if (!roy) {
        window.location.href = '/';
    } else {

    }
    const [name, setname] = useState("");

    let actualizar = () => {
        document.getElementById("miFormNoticia").reset();
    }
    const [auth, setAuth] = useState(false);

    const crear = e => {
        e.preventDefault();
        actualizar();
        const body = {
            name: name
        };

        fetch("https://noticiasdeportivas.herokuapp.com/deporte", {
            method: "POST",
            body: JSON.stringify(body),
            headers: { "Content-Type": "application/json" }
        })
            .then(res => res.json())
            .then(data => {
                sessionStorage.setItem("my_token", data.token);

                alert("Deporte se Creo");

            })
            .catch(err => console.log(err));
    };
    return (
        <div className="container-fluid p-0 m-0 accordion" id="gameCreate" >
            <div className="col-12 bg-title-edith d-flex justify-content-center align-items-center">
                <h1>Crear Deporte</h1>
            </div>
            <form onSubmit={crear} id="miFormNoticia">
                <div className="container g-0">
                    <div className="row g-0">
                        <div className="col-12 p-1">
                            <div className="col-12 title-lines text-center">Deporte</div>
                            <input className="form-control selectInner" type="text" placeholder="Deporte" aria-label="h1" onChange={e => setname(e.target.value)} />
                        </div>
                    </div>
                </div>
                <div className="col-10 text-end py-3">
                    <button type="submit" className="btn btn-danger">Create</button>
                </div>
            </form>
            {auth ? <Redirect to="/admin" /> : null}
        </div>
    )
}